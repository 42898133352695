import * as React from 'react';
import { IoClose } from "react-icons/io5";
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

interface SnackbarCloseButtonProps {
    snackbarKey: string | number;
  }
  
function SnackbarCloseButton({ snackbarKey }: SnackbarCloseButtonProps) {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <IoClose color='#fff' />
      </IconButton>
    );
  }
  
  export default SnackbarCloseButton;