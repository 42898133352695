import { Button, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import * as React from 'react';

const CookiesBar: React.FC = () => {

    const [cookiesAccepted, setCookiesAccepted] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (localStorage.getItem('fitim-cookies-accepted')) {
            setCookiesAccepted(true);
        }
    }, []);

    return (
        <>
        {
            !localStorage.getItem('fitim-cookies-accepted') && 
            <Box
                sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 9999,
                    background: '#fff',
                    padding: '15px 0',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
                    transition: 'all 0.25s ease-in-out',
                    transform: cookiesAccepted ? 'translateY(100%)' : 'translateY(0)',
                }}
            >
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography variant="body2" sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#212121',
                        lineHeight: '1.5',
                        letterSpacing: '0.5px',
                        textAlign: 'center',
                        margin: '0 15px',
                    }}>
                        Ta strona korzysta z plików cookies. Korzystając z niej wyrażasz zgodę na ich używanie, zgodnie z ustawieniami przeglądarki.
                    </Typography>
                    <Button variant="contained" sx={{
                        background: '#212121',
                        color: '#fff',
                        padding: '10px 20px',
                        fontSize: '14px',
                        fontWeight: 500,
                        letterSpacing: '0.5px',
                        textTransform: 'none',
                        borderRadius: '0',
                        boxShadow: 'none',
                        '&:hover': {
                            background: '#212121',
                            boxShadow: 'none',
                        }
                    }} onClick={() => {
                        localStorage.setItem('fitim-cookies-accepted', 'true');
                        setCookiesAccepted(true);
                    }}>
                        Akceptuję
                    </Button>
                </Container>
            </Box>
        }
        </>
    )
}

export default CookiesBar;