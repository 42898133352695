
import React, { useCallback, useEffect } from 'react';
import GlobalStyle from "./GlobalStyle"
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';

import Navbar from './shared/components/Navbar';

import { AuthContext } from './context/AuthContext';
import { useDispatch } from 'react-redux';
import useApi from './hooks/useApi';
import { API_UPDATELANG, API_USERDATA_ENDPOINT } from './utils/apiConf';
import { setUser, setLanguage } from './redux/slices/userDataSlice';
import CookiesBar from './shared/components/CookiesBar';
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import RoutesComponent from './routes/Routes';
import { t } from 'i18next';


const App: React.FC =  () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [newsletterOpen, setNewsletterOpen] = React.useState<boolean>(false);
  const [newsletterEmail, setNewsletterEmail] = React.useState<string>('');

  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const { request: requestApi } = useApi();
  const request = useCallback(requestApi, []);

  const { i18n } = useTranslation();


  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      const language = user.language || localStorage.getItem('fitim-lang') || 'pl';

      if (token && (!user.email || user.email === 'undefined' || !user.uid || !user.firstname || user.firstname === 'undefined' || !user.is_active || !user.language || user.language === '')) {
        try {
            const headers = {
                authorization: `Bearer ${token}`
            }
            const response = await request('GET', API_USERDATA_ENDPOINT, {
                language,
                headers
            });

            if (response.message === 'error' || response.status === 'error') {
                window.location.href = '/signout?msg=0';
            }

            dispatch(setUser(response.data.data[0]));
            setIsAuthenticated(true);
        } catch (error) {
            console.error(error);
        }
      } else {
        const notLoggedUser = {
          uid: -1,
          email: '',
          firstname: '',
          is_active: false,
          is_admin: false,
          language: language,
        }
        if (!user.email || user.email === 'undefined' || !user.uid || !user.firstname || user.firstname === 'undefined' || !user.is_active || !user.language || user.language === '') {
          dispatch(setUser(notLoggedUser));
        }
      }
    }

    fetchUserData();
  }, [dispatch, request]);


  const handleChangeLanguage = (lang: string) => {
    localStorage.setItem('fitim-lang', lang);
    i18n.changeLanguage(lang);

    const changeProfileLanguage = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const headers = {
            authorization: `Bearer ${token}`
          }
          const queryParams = {
            language: lang,
          }
          const response = await request('PUT', API_UPDATELANG, { 
            language: lang,
            headers,
            queryParams,
            noMessage: true,
          });
        } catch (error) {
          console.error(error);
        }
      }

      dispatch(setLanguage(lang));

    }
    changeProfileLanguage();
  }

  return (
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <meta name="keywords" content={t('meta.keywords')} />
          <meta name="author" content={t('meta.authors')} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:title" content={t('meta.title')} />
          <meta property="og:description" content={t('meta.description')} />
          <meta property="og:image" content={t('meta.og.image')} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <BrowserRouter>
          <GlobalStyle />
          <Box sx={{
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            minHeight: '100%',
            backgroundColor: '#f2f2f2',
          }}>
            <Navbar changeLanguage={handleChangeLanguage} />

            <RoutesComponent />

            <CookiesBar />
            
            <Modal 
              open={newsletterOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(0,0,0,.25)',
                zIndex: 9999,
              }}>
                <Box sx={{
                  background: '#fff',
                  padding: '30px',
                  borderRadius: '15px',
                  position: 'relative',
                  width: {
                    xs: '90%',
                    sm: '500px',
                  },
                }}>
                  <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                  }}>
                    <IconButton onClick={() => setNewsletterOpen(false)}>
                      <IoClose />
                    </IconButton>
                  </Box>
                  <Typography variant="h5" sx={{ marginBottom: '15px', textAlign: 'center', }}>
                    Dołącz do newslettera
                  </Typography>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontSize: '14px', 
                      maxWidth: {
                        xs: '100%',
                        sm: '500px',
                      }
                    }}>
                    Bądź na bierząco z najnowszymi przepisami i artykułami! Dołącz by otrzymywać powiadomienia o nowych wpisach na swoją skrzynkę.
                  </Typography>
                  <form>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '15px',
                      marginTop: '15px',
                    }}>
                      <TextField 
                        type="email" 
                        placeholder="Twój adres e-mail" 
                        fullWidth 
                        value={newsletterEmail}
                        onChange={(e) => setNewsletterEmail(e.target.value)}
                      />
                      <Button variant='contained' sx={{ fontWeight: 'bold' }} fullWidth>Zapisz się</Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Modal>
          </Box>
        </BrowserRouter>
      </AuthContext.Provider>
  );
}

export default App;