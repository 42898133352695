import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';

import Footer from '../shared/components/Footer';
import Products from '../pages/products/Products';
import ProductPage from '../pages/products/ProductPage';
import Recipes from '../pages/recipe/Recipes';
import ActivateEmail from '../pages/singninup/ActivateEmail';

const RecipeFinder = lazy(() => import('../pages/recipeFinder/RecipeFinder'));
const Register = lazy(() => import('../pages/singninup/Register'));
const Login = lazy(() => import('../pages/singninup/Login'));
const RecipePage = lazy(() => import('../pages/recipe/RecipePage'));
const Rules = lazy(() => import('../pages/legal/Rules'));
const Policy = lazy(() => import('../pages/legal/Policy'));
const Rodo = lazy(() => import('../pages/legal/Rodo'));
const ResetPassword = lazy(() => import('../pages/singninup/Reset'));
const NewPassword = lazy(() => import('../pages/singninup/NewPassword'));
const Ingredients = lazy(() => import('../pages/ingredients/Ingredients'));
const ErrorPage = lazy(() => import('../pages/error/components/ErrorPage'));
const Blog = lazy(() => import('../pages/blog/Blog'));
const PostPage = lazy(() => import('../pages/blog/PostPage'));
const Logout = lazy(() => import('../pages/singninup/Logout'));

const Dashboard = lazy(() => import('../pages/panel/views/general/Dashboard'));
const RecipeAdd = lazy(() => import('../pages/panel/views/recipe/Add'));
const TagAdd = lazy(() => import('../pages/panel/views/tag/Add'));
const TagEdit = lazy(() => import('../pages/panel/views/tag/Edit'));
const IngredientAdd = lazy(() => import('../pages/panel/views/ingredient/Add'));
const IngredientList = lazy(() => import('../pages/panel/views/ingredient/List'));
const IngredientEdit = lazy(() => import('../pages/panel/views/ingredient/Edit'));
const CategoryAdd = lazy(() => import('../pages/panel/views/categories/Add'));
const CategoryEdit = lazy(() => import('../pages/panel/views/categories/Edit'));
const ProductAdd = lazy(() => import('../pages/panel/views/product/Add'));
const ProductList = lazy(() => import('../pages/panel/views/product/List'));
const ProductEdit = lazy(() => import('../pages/panel/views/product/Edit'));
const PasswordChange = lazy(() => import('../pages/panel/views/security/PasswordChange'));
const RecipeList = lazy(() => import('../pages/panel/views/recipe/List'));
const RecipeEdit = lazy(() => import('../pages/panel/views/recipe/Edit'));
const Preferences = lazy(() => import('../pages/panel/views/general/Preferences'));
const ArticleAdd = lazy(() => import('../pages/panel/views/blog/post/Add'));
const ArticleList = lazy(() => import('../pages/panel/views/blog/post/List'));
const PostEdit = lazy(() => import('../pages/panel/views/blog/post/Edit'));
const IngredientPage = lazy(() => import('../pages/ingredients/IngredientPage'));

const RoutesComponent: React.FC = () => {
    const location = useLocation();

    const pagesWithoutFooter = ['/signin', '/signup', '/reset', '/reset/:token', '/404'];

    return (
        <>
        <Routes>
            {/* Home page */}
            <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><RecipeFinder /></Suspense>} />
            
            {/* Register page */}
            <Route path="/signup" element={<Suspense fallback={<div>Loading...</div>}><Register /></Suspense>} />
            
            {/* Login page */}
            <Route path="/signin" element={<Suspense fallback={<div>Loading...</div>}><Login /></Suspense>} />

            {/* Logout page */}
            <Route path="/signout" element={<Suspense fallback={<div>Loading...</div>}><Logout /></Suspense>} />

            {/* Reset Password Page */}
            <Route path="/reset" element={<Suspense fallback={<div>Loading...</div>}><ResetPassword /></Suspense>} />
            <Route path="/reset/:token" element={<Suspense fallback={<div>Loading...</div>}><NewPassword /></Suspense>} />

            {/* Actiavte E-mail */}
            <Route path="/account/activate/:hash" element={<Suspense fallback={<div>Loading...</div>}><ActivateEmail /></Suspense>} />

            {/* Legal pages */}
            <Route path="/rules" element={<Suspense fallback={<div>Loading...</div>}><Rules /></Suspense>} />
            <Route path="/privacypolicy" element={<Suspense fallback={<div>Loading...</div>}><Policy /></Suspense>} />
            <Route path="/rodo" element={<Suspense fallback={<div>Loading...</div>}><Rodo /></Suspense>} />

            {/* Recipe page */}
            <Route path='/recipes' element={<Suspense fallback={<div>Loading...</div>}><Recipes /></Suspense>} />
            <Route path='/recipes/:slug' element={<Suspense fallback={<div>Loading...</div>}><RecipePage /></Suspense>} />

            {/* Ingredients page */}
            <Route path="/ingredients" element={<Suspense fallback={<div>Loading...</div>}><Ingredients /></Suspense>} />
            <Route path="/ingredients/:slug" element={<Suspense fallback={<div>Loading...</div>}><IngredientPage /></Suspense>} />

            {/* Products page */}
            <Route path="/products" element={<Suspense fallback={<div>Loading...</div>}><Products /></Suspense>} />
            <Route path="/products/:slug" element={<Suspense fallback={<div>Loading...</div>}><ProductPage /></Suspense>} />

            {/* Blog page */}
            <Route path="/blog" element={<Suspense fallback={<div>Loading...</div>}><Blog /></Suspense>} />
            <Route path="/blog/post/:slug" element={<Suspense fallback={<div>Loading...</div>}><PostPage /></Suspense>} />

            {/* Panel */}
            <Route path="/panel/dashboard" element={<Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>} />
            <Route path="/panel/preferences" element={<Suspense fallback={<div>Loading...</div>}><Preferences /></Suspense>} />
            
            <Route path="/panel/recipes/add" element={<Suspense fallback={<div>Loading...</div>}><RecipeAdd /></Suspense>} />
            <Route path="/panel/recipes/list" element={<Suspense fallback={<div>Loading...</div>}><RecipeList /></Suspense>} />
            <Route path='/panel/recipes/edit/:id' element={<Suspense fallback={<div>Loading...</div>}><RecipeEdit /></Suspense>} />

            <Route path='/panel/tags/add' element={<Suspense fallback={<div>Loading...</div>}><TagAdd /></Suspense>} />
            <Route path='/panel/tags/list' element={<Suspense fallback={<div>Loading...</div>}><TagEdit /></Suspense>} />
            <Route path='/panel/tags/edit/:id' element={<Suspense fallback={<div>Loading...</div>}><TagEdit /></Suspense>} />

            <Route path='/panel/ingredients/add' element={<Suspense fallback={<div>Loading...</div>}><IngredientAdd /></Suspense>} />
            <Route path='/panel/ingredients/list' element={<Suspense fallback={<div>Loading...</div>}><IngredientList /></Suspense>} />
            <Route path='/panel/ingredients/edit/:id' element={<Suspense fallback={<div>Loading...</div>}><IngredientEdit /></Suspense>} />

            <Route path='/panel/categories/add' element={<Suspense fallback={<div>Loading...</div>}><CategoryAdd /></Suspense>} />
            <Route path='/panel/categories/list' element={<Suspense fallback={<div>Loading...</div>}><CategoryEdit /></Suspense>} />
            <Route path='/panel/categories/edit/:type/:id' element={<Suspense fallback={<div>Loading...</div>}><CategoryEdit /></Suspense>} />

            <Route path='/panel/products/add' element={<Suspense fallback={<div>Loading...</div>}><ProductAdd /></Suspense>} />
            <Route path='/panel/products/list' element={<Suspense fallback={<div>Loading...</div>}><ProductList /></Suspense>} />
            <Route path='/panel/products/edit/:id' element={<Suspense fallback={<div>Loading...</div>}><ProductEdit /></Suspense>} />

            <Route path='/panel/blog/post/add' element={<Suspense fallback={<div>Loading...</div>}><ArticleAdd /></Suspense>} />
            <Route path='/panel/blog/post/list' element={<Suspense fallback={<div>Loading...</div>}><ArticleList /></Suspense>} />
            <Route path='/panel/blog/post/edit/:id' element={<Suspense fallback={<div>Loading...</div>}><PostEdit /></Suspense>} />

            <Route path='/panel/security/change-password' element={<Suspense fallback={<div>Loading...</div>}><PasswordChange /></Suspense>} />

            <Route path="/404" element={<Suspense fallback={<div>Loading...</div>}><ErrorPage /></Suspense>} />
            <Route path="*" element={<Navigate to='/404' />} />
        </Routes>
        {!pagesWithoutFooter.includes(location.pathname) && !location.pathname.startsWith('/panel/') && <Footer />}           
        </>
    )
}

export default RoutesComponent;