import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    html {
        height: 100%;
        box-sizing: border-box;
        background-color: #f2f2f2;
    }

    *,*::before,*::after {
        box-sizing: inherit;
        /* border: 1px solid red; */
    }

    body {
        margin: 0;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        overflow: auto !important;
        padding-right: 0 !important;
    }

    #root {
        height: 100%;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    @keyframes rotateCoin {
        0% {
            transform: rotateY(0deg);
        }
        50% {
            transform: rotateY(720deg);
        }
        100% {
            transform: rotateY(1440deg);
        }
    }

    .rotate {
        animation: rotateCoin 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    }


`;

export default GlobalStyle;