import { useSnackbar } from 'notistack';
import { Container } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useApi from '../../hooks/useApi';
import { API_ACTIVATE_ACCOUNT_ENDPOINT } from '../../utils/apiConf';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// Yup validation schema

const ActivateEmail: React.FC = ()  => {

    const hash = useParams<{ hash: string }>();

    const { request } = useApi();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();


    useEffect(() => {
        if (hash.hash) {
            sendActivateEmail(hash.hash);
        } else {
            navigate('/');
        }
    }, []);

    const sendActivateEmail = async (hash: string) => {
        const language = user.language ? user.language : localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';

        const response = await request('GET', `${API_ACTIVATE_ACCOUNT_ENDPOINT}?hash=${hash}`, {
            language
        });

        if (response.status === 200) {
            navigate('/signin');
        } else {
            navigate('/');
        }
    }

    return(
        <>
        <Helmet>
            <title>Activating e-mail...</title>
        </Helmet>
        <Container 
            sx={{
                position: 'relative',
                display: 'flex',
                maxWidth: '100% !important',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            >
        </Container>
        </>
    );
}

export default ActivateEmail;