const API_OLD_BASE_URL = 'https://api-smakomat.getbot.eu';
const API_BASE_URL ='https://api.fitim.pl';

const API_CDN_URL = 'https://cdn.fitim.pl';
const API_CDN_URL_OLD = 'https://cdn-smakomat.getbot.eu';

const API_INGREDIENT_SEARCH_ENDPOINT = `/Search/ingredient`;
const API_RECIPES_SEARCH_ENDPOINT = `/Search/recipes`;
const API_RECIPE_SEARCH_ENDPOINT = `/Search/Recipe`;
const API_CATEGORIES_SEARCH_ENDPOINT = `/Search/Categories`;
const INGREDIENT_PHOTOS_URL = `${API_CDN_URL}/uploads/`;
const RECIPE_PHOTOS_URL = `${API_CDN_URL}/uploads/`;
// const API_INGREDIENT_GETALL_ENDPOINT = `/Search/ingredient?name=all&type=show`;
// ! AUTH
const API_USERDATA_ENDPOINT = `/Auth/me`;
const API_RESETPASSWORD_ENDPOINT = `/Auth/resetpass`;
const API_UPDATEPASSWORD_ENDPOINT = `/Auth/updatepass`;
const API_UPDATELANG = `/Auth/updatelang`;
const API_LOGIN_ENDPOINT = `/Auth/login`;
const API_REGISTER_ENDPOINT = `/Auth/register`;
const API_VERYFY_TOKEN_ENDPOINT = `/Auth/verifyresetpasstoken`;
const API_RESETPASSTOKEN_ENDPOINT = `/Auth/resetpasstoken`;
const API_LOGIN_FACEBOOK_ENDPOINT = `/Auth/facebooklogin`;
const API_LOGIN_GOOGLE_ENDPOINT = `/Auth/googlelogin`;
const API_ACTIVATE_ACCOUNT_ENDPOINT = `/Auth/emailconfirm`;
// ! Upload
const API_UPLOAD_IMAGE_ENDPOINT = `/Uploads/upload`;
const API_UPLOAD_GET_ENDPOINT = `/Uploads/getall`;
// ! Ingredients
const API_INGREDIENT_ADD_ENDPOINT = `/Ingredients/add`;
const API_INGREDIENT_EDIT_ENDPOINT = `/Ingredients/edit`;
const API_INGREDIENT_GETALL_ENDPOINT = `/Ingredients/getall`;
const API_INGREDIENT_GET_ENDPOINT = `/Ingredients/get`;
const API_INGREDIENT_GETBYSLUG = `/Ingredients/getbyslug`;
const API_INGREDIENT_DELETE_ENDPOINT = `/Ingredients/delete`;
// ! Products
const API_PRODUCT_ADD_ENDPOINT = `/Products/add`;
const API_PRODUCT_EDIT_ENDPOINT = `/Products/edit`;
const API_PRODUCT_GETALL_ENDPOINT = `/Products/getall`;
const API_PRODUCT_GET_ENDPOINT = `/Products/get`;
const API_PRODUCT_DELETE_ENDPOINT = `/Products/delete`;
const API_PRODUCT_GETBYSLUG = `/Products/getbyslug`;
// ! Recipes
const API_RECIPE_ADD_ENDPOINT = `/Recipe/add`;
const API_RECIPE_GET_ENDPOINT = `/Recipe/get`;
const API_RECIPE_GETBYSLUG = `/Recipe/getbyslug`;
const API_RECIPES_GETALL_ENDPOINT = `/Recipe/getall`;
const API_RECIPES_GETBYSLUG_ENDPOINT = `/Recipe/getbyslug`;
const API_RECIPE_DELETE_ENDPOINT = `/Recipe/delete`;
const API_RECIPE_RECOVER_ENDPOINT = `/Recipe/recover`;
const API_RECIPE_EDIT_ENDPOINT = `/Recipe/edit`;
const API_RECIPE_INGREDIENT_ADD_ENDPOINT = `/Recipe/AddUpdateRecipeIngredients`;
const API_RECIPE_COMMENT_GET_ENDPOINT = `/Recipe/comment/get`;
const API_RECIPE_COMMENT_GETALL_ENDPOINT = `/Recipe/comment/getall`;
const API_RECIPE_COMMENT_ADD_ENDPOINT = `/Recipe/comment/add`;
const API_RECIPE_COMMENT_DELETE_ENDPOINT = `/Recipe/comment/delete`;
const API_RECIPE_COMMENT_EDIT_ENDPOINT = `/Recipe/comment/edit`;
// ! Tag
const API_TAG_ADD_ENDPOINT = `/Tag/add`;
const API_TAG_DELETE_ENDPOINT = `/Tag/delete`;
const API_TAG_GETALL_ENDPOINT = `/Tag/getall`;
const API_TAG_EDIT_ENDPOINT = `/Tag/edit`;
// ! Category 
const API_CATEGORY_ADD_ENDPOINT = `/Category/add`;
const API_CATEGORY_GET_ENDPOINT = `/Category/get`;
const API_CATEGORY_EDIT_ENDPOINT = `/Category/edit`;
const API_CATEGORY_GETALL_ENDPOINT = `/Category/getall`;
const API_CATEGORY_DELETE_ENDPOINT = `/Category/delete`;
// ! Blog
const API_POST_GET_ENDPOINT = `/Blog/post/get`;
const API_POST_GET_SLUG_ENDPOINT = `/Blog/post/getbyslug`;
const API_BLOG_GETALLPOSTS_ENDPOINT = `/Blog/post/getall`;
const API_POST_ADD_ENDPOINT = `/Blog/post/add`;
const API_POST_EDIT_ENDPOINT = `/Blog/post/edit`;
const API_POST_DELETE_ENDPOINT = `/Blog/post/delete`;
const API_POST_RECOVER_ENDPOINT = `/Blog/post/recover`;
const API_POST_COMMENTS_GETALL_ENDPOINT = `/Blog/comment/getall`;
const API_POST_COMMENTS_ADD_ENDPOINT = `/Blog/comment/add`;
const API_POST_COMMENTS_DELETE_ENDPOINT = `/Blog/comment/delete`;
const API_POST_COMMENTS_EDIT_ENDPOINT = `/Blog/comment/edit`;

// ! Preferences
const API_PREFERENCES_GET_PATH = `/Preference`;
const API_PREFERENCES_GET_ENDPOINT = `${API_PREFERENCES_GET_PATH}/getall`;
const API_PREFERENCES_ADD_ENDPOINT = `${API_PREFERENCES_GET_PATH}/add`;
const API_PREFERENCES_DELETE_ENDPOINT = `${API_PREFERENCES_GET_PATH}/delete`;
const API_PREFERENCES_EDIT_ENDPOINT = `${API_PREFERENCES_GET_PATH}/edit`;
// ! Newsletter
const API_NEWSLETTER_PATH = `/Newsletter`;
const API_NEWSLETTER_REGISTER_ENDPOINT = `${API_NEWSLETTER_PATH}/Register`;
const API_NEWSLETTER_SEND_ENDPOINT = `${API_NEWSLETTER_PATH}/Send`;

export {
    API_BASE_URL,
    API_OLD_BASE_URL,
    API_CDN_URL,
    API_CDN_URL_OLD,
    INGREDIENT_PHOTOS_URL,
    API_INGREDIENT_SEARCH_ENDPOINT,
    API_RECIPES_SEARCH_ENDPOINT,
    RECIPE_PHOTOS_URL,
    API_RECIPE_SEARCH_ENDPOINT,
    API_RESETPASSWORD_ENDPOINT,
    API_LOGIN_ENDPOINT,
    API_REGISTER_ENDPOINT,
    API_UPDATEPASSWORD_ENDPOINT,
    API_VERYFY_TOKEN_ENDPOINT,
    API_RESETPASSTOKEN_ENDPOINT,
    API_LOGIN_FACEBOOK_ENDPOINT,
    API_LOGIN_GOOGLE_ENDPOINT,
    API_USERDATA_ENDPOINT,
    API_INGREDIENT_ADD_ENDPOINT,
    API_INGREDIENT_GET_ENDPOINT,
    API_INGREDIENT_GETALL_ENDPOINT,
    API_RECIPE_ADD_ENDPOINT,
    API_RECIPE_INGREDIENT_ADD_ENDPOINT,
    API_CATEGORIES_SEARCH_ENDPOINT,
    API_BLOG_GETALLPOSTS_ENDPOINT,
    API_POST_GET_ENDPOINT,
    API_PREFERENCES_GET_ENDPOINT,
    API_PREFERENCES_ADD_ENDPOINT,
    API_PREFERENCES_DELETE_ENDPOINT,
    API_POST_ADD_ENDPOINT,
    API_POST_EDIT_ENDPOINT,
    API_POST_DELETE_ENDPOINT,
    API_INGREDIENT_DELETE_ENDPOINT,
    API_RECIPE_DELETE_ENDPOINT,
    API_NEWSLETTER_REGISTER_ENDPOINT,
    API_NEWSLETTER_SEND_ENDPOINT,
    API_UPDATELANG,
    API_TAG_ADD_ENDPOINT,
    API_TAG_DELETE_ENDPOINT,
    API_TAG_GETALL_ENDPOINT,
    API_TAG_EDIT_ENDPOINT,
    API_UPLOAD_IMAGE_ENDPOINT,
    API_CATEGORY_ADD_ENDPOINT,
    API_CATEGORY_GET_ENDPOINT,
    API_CATEGORY_EDIT_ENDPOINT,
    API_CATEGORY_GETALL_ENDPOINT,
    API_CATEGORY_DELETE_ENDPOINT,
    API_INGREDIENT_EDIT_ENDPOINT,
    API_UPLOAD_GET_ENDPOINT,
    API_PRODUCT_ADD_ENDPOINT,
    API_PRODUCT_EDIT_ENDPOINT,
    API_PRODUCT_GETALL_ENDPOINT,
    API_PRODUCT_GET_ENDPOINT,
    API_PRODUCT_DELETE_ENDPOINT,
    API_RECIPE_GET_ENDPOINT,
    API_RECIPES_GETALL_ENDPOINT,
    API_RECIPE_RECOVER_ENDPOINT,
    API_RECIPE_EDIT_ENDPOINT,
    API_POST_RECOVER_ENDPOINT,
    API_POST_GET_SLUG_ENDPOINT,
    API_POST_COMMENTS_GETALL_ENDPOINT,
    API_POST_COMMENTS_ADD_ENDPOINT,
    API_POST_COMMENTS_DELETE_ENDPOINT,
    API_POST_COMMENTS_EDIT_ENDPOINT,
    API_INGREDIENT_GETBYSLUG,
    API_PRODUCT_GETBYSLUG,
    API_RECIPE_GETBYSLUG,
    API_RECIPES_GETBYSLUG_ENDPOINT,
    API_RECIPE_COMMENT_GET_ENDPOINT,
    API_RECIPE_COMMENT_GETALL_ENDPOINT,
    API_RECIPE_COMMENT_ADD_ENDPOINT,
    API_RECIPE_COMMENT_DELETE_ENDPOINT,
    API_RECIPE_COMMENT_EDIT_ENDPOINT,
    API_PREFERENCES_EDIT_ENDPOINT,
    API_ACTIVATE_ACCOUNT_ENDPOINT
}