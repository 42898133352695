import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../../../utils/types';

const ProductCard = ({ product }: {product: ProductType}) => {
    const { t } = useTranslation();

    // Przeliczanie wartości na 100g/100ml
    const calculatePer100g = (value: number, size: number, sizeType: string) => {
        if (sizeType === 'g' || sizeType === 'ml') {
            if (size === 100) {
                return value;
            } else {
                return (value * 100) / size;
            }
        } else {
            return value;
        }
    };

    return (
        <Table aria-label="product table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 700 }}>{product.name}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700 }}>{`${t('product.stats100')} ${product.size_type}`}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700 }}>{`${t('product.statsFull')} ${product.size} ${product.size_type}`}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* Wartości odżywcze */}
                <TableRow>
                    <TableCell>{t('product.kcal')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.kcal, product?.size, product?.size_type)} kcal</TableCell>
                    <TableCell align="right">{product?.kcal} kcal</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.proteins')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.protein, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.protein.toFixed(2)} g</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.fats')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.fat, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.fat.toFixed(2)} g</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.carbs')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.carbohydrates, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.carbohydrates.toFixed(2)} g</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.sugar')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.carbohydrates_sugar, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.carbohydrates_sugar.toFixed(2)} g</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.fiber')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.fiber, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.fiber.toFixed(2)} g</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('product.salt')}</TableCell>
                    <TableCell align="right">{calculatePer100g(product?.salt, product?.size, product?.size_type).toFixed(2)} g</TableCell>
                    <TableCell align="right">{product?.salt.toFixed(2)} g</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default ProductCard;