import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './shared/components/SnackbackClose';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <SnackbarProvider
            preventDuplicate
            autoHideDuration={4500}
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
          <App />
        </SnackbarProvider>
      </Provider>
    </I18nextProvider>
  // </React.StrictMode>
);