import * as React from 'react';
import useApi from '../../hooks/useApi';
import { Container } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { PostType } from '../../utils/types';
import { API_RECIPES_GETALL_ENDPOINT } from '../../utils/apiConf';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Post from '../components/Post';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';

const Recipes: React.FC = () => {

    const { request } = useApi();
    const [posts, setPosts] = React.useState<PostType[]>([]);
    const [firstLoad, setFirstLoad] = React.useState<boolean>(false);

    const user = useSelector((state: RootState) => state.user);


    React.useEffect(() => {

        const fetchPosts = async () => {

            const language = user.language ? user.language : localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';

            const response = await request('GET', API_RECIPES_GETALL_ENDPOINT, {
                language,
                noMessage: true,
            });
            
            if (response.status === 200) {
                setPosts(response.data.data.recipes);
                setFirstLoad(true);
            } else {
                enqueueSnackbar('Nie udało się pobrać postów.', { variant: 'error' });
                setFirstLoad(true);
            }
        }
        fetchPosts();
    }, []);

    return (
        <>
        <Helmet>
            <title>{t('titles.recipes')}</title>
            <meta name="description" content={t('descriptions.recipes')} />
            <meta property="og:title" content={t('titles.recipes')} />
            <meta property="og:description" content={t('descriptions.recipes')} />
            <meta property='twitter:title' content={t('titles.recipes')} />
            <meta property='twitter:description' content={t('descriptions.recipes')} />
            <meta property="og:url" content={window.location.href} />
        </Helmet>
        <Container maxWidth='lg' sx={{
            padding: {
                lg: '50px',
                md: '50px',
                sm: '20px',
                xs: '10px',
            }
        }}>
            <Grid container spacing={2} columns={{
                xs: 4, sm: 4, md: 8, lg: 12,
            }}>
                { posts && posts.length === 0 && <Typography width={'100%'} variant='h2' fontSize={20} align='center' marginBottom={5}>
                    { firstLoad ? 'Brak postów' : 'Ładowanie...'}
                </Typography> }
                {
                    // First two posts will be bigger
                    posts && posts.length > 0 && posts.slice(0, 2).map((post: PostType) =>
                        <Grid key={post.id} item lg={6} md={4} sm={4} xs={4}>
                            <Post data={post} type='recipe' />
                        </Grid>
                    )
                }
                {
                    posts && posts.length > 2 && posts.slice(2).map((post: PostType) =>
                    <Grid key={post.id} item lg={4} md={4} sm={2} xs={4}>
                        <Post data={post} type='recipe' />
                    </Grid>
                )}
            </Grid>
        </Container>
        </>
    );
}

export default Recipes;