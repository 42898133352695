import { useReducer } from 'react';
import { API_BASE_URL } from '../utils/apiConf';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

const apiReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        data: null,
        error: null,
        loading: true,
      };
    case 'SUCCESS':
      return {
        data: action.payload,
        error: null,
        loading: false,
      };
    case 'ERROR':
      return {
        data: null,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};


const useApi = () => {

  const [state, dispatch] = useReducer(apiReducer, {
    data: null,
    error: null,
    loading: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  
  const request = async(
    method: string, 
    endpoint: string,
    options: { 
      language: string,
      headers?: Record<string, string>,
      body?: any, 
      queryParams?: Record<string, string>,
      noMessage?: boolean,
      contentType?: string,
      noJson?: boolean,
    }) => {
      try {
        dispatch({ type: 'REQUEST' });

        // const apiUrl = 'https://localhost:7027';
        let paramSign = '?';
        if (endpoint.includes('?')) {
          paramSign = '&';
        }

        const fullUrl = `${API_BASE_URL}${endpoint}${paramSign}lang=${options?.language}`;
        
        // if (options?.queryParams) {
        //   console.log("Requesting ", fullUrl, options?.queryParams);
        // } else {
        //   console.log("Requesting ", fullUrl);
        // }

        let headers = {
          ...(options?.headers || {}),
        }

        // if contentType === 'multipart/form-data' dont add to header, otherwise add with default application/json
        if (options?.contentType !== 'multipart/form-data') {
          headers['Content-Type'] = 'application/json';
        }

        let body;

        if (options?.noJson) {
          if (options?.queryParams?.file) {
            body = new FormData();
            body.append('file', options.queryParams.file);
            // to się przekazuje do body w fetchu
          } else {
            body = new URLSearchParams(options.queryParams);
          }
        } else {
          body = JSON.stringify(options?.queryParams);
        }

        console.log(fullUrl)

        const response = await fetch(fullUrl, {
          method,
          headers,
          body,
        });

        if (!response.ok && response.status !== 429) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message;
          const status = response.status;

          if (!options.noMessage) {
            await enqueueSnackbar(errorMessage, { variant: 'error' });
          } else if (response.status === 401) {
            enqueueSnackbar(t('forms.common.errors.noAuth'), { variant: 'error' });
          }
          
          return { data: {}, status };
        } else if (response.status === 429) {
          const status = response.status;

          return { data: {}, status }
        }
        
        const result = await response.json();
        
        dispatch({ type: 'SUCCESS', payload: result });

        if (result.message && !options.noMessage) {
          enqueueSnackbar(result.message, { variant: 'success' });
        }

        return { data: result, status: response.status };
      } catch (error: any) {
        dispatch({ type: 'ERROR', payload: error })
        console.error('Request error: ', error);
        return {error, status: null, message: 'error'};
      }
  }
  return { ...state, request };
};

export default useApi;

