import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL, API_OLD_BASE_URL, API_PREFERENCES_GET_ENDPOINT } from '../../utils/apiConf';
import { PrefType } from '../../utils/types';

type PreferenceState = Array<PrefType>;

const initialState: PreferenceState = [];

export const fetchPreferences = createAsyncThunk(
    'preferences/fetchPreferences',
    async (language: string) => {
      const token = localStorage.getItem('token');
      const headers = {
          'Authorization': `Bearer ${token}`
      }
      try {
        const response = await fetch(`${API_BASE_URL}${API_PREFERENCES_GET_ENDPOINT}?lang=${language ? language : 'pl'}`, { 
            method: 'GET',
            headers: headers,
        });
  
        const data = await response.json();
        return data.data.preferences;
      } catch(error) {
        console.error('Error fetching preferences:', error);
      }
    }
  );

export const preferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
      setPreferences: (state, action: PayloadAction<PreferenceState>) => {
        return action.payload;
      },
      addPreference: (state, action: PayloadAction<PrefType>) => {
        state.push(action.payload);
      },
      removePreference: (state, action: PayloadAction<number>) => {
        return state.filter(pref => pref.id !== action.payload);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchPreferences.fulfilled, (state, action) => {
        return action.payload;
      });
    },
  });

export const { setPreferences, addPreference, removePreference } = preferenceSlice.actions;

export default preferenceSlice.reducer;