import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type IngredientType = {
    id: number,
    name: string,
    randomColor: string,
}

type IngredientState = {
    ingredients: IngredientType[] | null,
}

const initialState: IngredientState = {
    ingredients: null,
}

const ingredientsSlice = createSlice({
    name: 'ingredients',
    initialState,
    reducers: {
        setIngredients: (state: any, action: PayloadAction<IngredientType[]>) => {
            state.ingredients = action.payload;
        },
        clearIngredients: (state) => {
            state.ingredients = null;
        },
    },
});

export const { setIngredients, clearIngredients } = ingredientsSlice.actions;
export default ingredientsSlice.reducer;