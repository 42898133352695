import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const recipeIdSlice = createSlice({
    name: 'recipeId',
    initialState: {
        recipeId: 0,
    },
    reducers: {
        setRecipeId: (state, action: PayloadAction<number>) => {
            state.recipeId = action.payload;
        }
    }
})

export const { setRecipeId } = recipeIdSlice.actions;

export default recipeIdSlice.reducer;