import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommentType } from '../../utils/types';
import { API_BASE_URL, API_POST_COMMENTS_GETALL_ENDPOINT, API_RECIPE_COMMENT_GETALL_ENDPOINT } from '../../utils/apiConf';

interface CommentState {
    comments: CommentType[]; // Zdefiniuj CommentType według struktury komentarza
}

const initialState: CommentState = {
    comments: [],
};

export const fetchComments = createAsyncThunk(
    'comments/fetchComments',
    async ({id, type}: { id: number, type: string }) => {
        const language = localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';
        const token = localStorage.getItem('token');
        const headers = { 'authorization': `Bearer ${token}` };
        if (type === 'recipe') {
            const response = await fetch(`${API_BASE_URL}${API_RECIPE_COMMENT_GETALL_ENDPOINT}?id=${id}&lang=${language}`, {
                headers,
                method: 'GET',
            });
            const data = await response.json();
            console.log(data);
            return data.data.comments;
        } else if (type === 'blog') {
            const response = await fetch(`${API_BASE_URL}${API_POST_COMMENTS_GETALL_ENDPOINT}?id=${id}&lang=${language}`, {
                headers,
                method: 'GET',
            });
            const data = await response.json();
            return data.data.comments;
        }
    }
);

export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        setComments: (state, action: PayloadAction<CommentType[]>) => {
            state.comments = action.payload;
        },
        addComment: (state, action: PayloadAction<CommentType>) => {
            state.comments.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchComments.fulfilled, (state, action) => {
            state.comments = action.payload;
        });
    },
});

export const { setComments, addComment } = commentSlice.actions;

export default commentSlice.reducer;