import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecipeType } from "../../utils/types";


type RecipesState = {
    recipes: RecipeType[];
}

const initialState: RecipesState = {
    recipes: [],
}

const recipesSlice = createSlice({
    name: "recipes",
    initialState,
    reducers: {
        setRecipes: (state: any, action: PayloadAction<RecipeType[]>) => {
            state.recipes = action.payload;
        },
        clearRecipes: (state) => {
            state.recipes = [];
        },
    },
});

export const { setRecipes, clearRecipes } = recipesSlice.actions;
export default recipesSlice.reducer;