import React, { useEffect } from 'react';
import { API_CDN_URL } from '../../utils/apiConf';

const ScallableImage = ({ src, alt, width }: {src: string, alt: string, width: string}) => {

    const [otherWidths, setOtherWidths] = React.useState<number[]>([]);

    useEffect(() => {
        const widths = getOtherWidths(width);
        if (widths) {
            setOtherWidths(widths);
        }
    }, []);

    const getOtherWidths = (width: string) => {
        let w = parseInt(width);
        if (w > 300) {
            let arr = [w];
            while (w > 300) {
                w = Math.round(w / 2);
                arr.push(w);
            }
            // if (arr[arr.length - 1] !== 300)
                arr.push(300);
            return arr;
        }
    }

  return (
    <picture>
      {
        otherWidths.map((w, i) => {

          return (
            <source key={i} srcSet={`${API_CDN_URL}/uploads/${src}?w=${w}`}media={`(min-width: ${otherWidths[i+1] ? otherWidths[i+1] + 1 : 0}px)`} />
          )
        })
      }
      <img style={{ width: '100%' }} src={`${API_CDN_URL}/uploads/${src}?w=${width}`} alt={alt} width={width} />
    </picture>
  );
};

export default ScallableImage;