import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { API_CDN_URL } from '../../utils/apiConf';
import { PostType } from '../../utils/types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaClock } from 'react-icons/fa';
import ScallableImage from '../../shared/components/ScallableImage';
import { useTranslation } from 'react-i18next';

const Post: React.FC<{ data: any, type: string }> = ({data, type}) => {

    const { t } = useTranslation();

    console.log(data);

    return (
        <Box data-postid={data.title}>
            <Link to={`${type === 'article' 
                ? '/blog/post' : type === 'recipe' 
                    ? '/recipes' : '404' }/${data.slug}`} 
                style={{textDecoration: 'none', color: 'black'}}
            >             
                <Box sx={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                }}>
                    <ScallableImage src={data.image ? data.image : 'null.png'} alt={data.title} width='600' />

                    <Box sx={{
                        padding: '15px',
                        position: 'relative',
                    }}>
                        <Box sx={{
                            position: 'absolute',
                            top: '0',
                            right: '20px',
                            translate: '0 -50%',
                            backgroundColor: '#fafafa',
                            padding: '5px 10px',
                            borderRadius: '25px',
                            color: 'black',
                            fontWeight: '700',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}>
                            {
                                type === 'article' ? 
                                <>
                                    <FaClock style={{ color: '#5b5b5b', fontSize: '14px', marginRight: '5px' }} />
                                    <Typography variant='h3' sx={{ fontSize: '16px', color: '#5b5b5b' }}>
                                        {t('recipes.readInShort')} {data.reading_time < 60 ? `${data.reading_time} ${t('recipes.minutes')}` : `${Math.floor(data.reading_time / 60)}h ${data.reading_time % 60}min`}
                                    </Typography>
                                </> : 
                                <>
                                    <FaClock style={{ color: '#5b5b5b', fontSize: '14px', marginRight: '5px' }} />
                                    <Typography variant='h3' sx={{ fontSize: '16px', color: '#5b5b5b' }}>
                                        {t('recipes.cookIn')} {data.cook_time < 60 ? `${data.cook_time} ${t('recipes.minutes')}` : `${Math.floor(data.cook_time / 60)}h ${data.cook_time % 60}min`}
                                    </Typography>
                                </>
                            }
                        </Box>
                        <Typography variant='h3' sx={{
                            fontSize: '20px',
                            fontWeight: '700', 
                            letterSpacing: '1px', 
                            padding: '5px 0', 
                            '@media(max-width: 600px)': {
                                fontSize: '18px',
                            }
                         }}
                        >
                            {data.title}
                        </Typography>
                        <Typography variant='h3' sx={{ 
                            fontSize: '16px', 
                            color: '#5b5b5b',
                            lineHeight: '1.5',
                            textAlign: 'justify',
                            marginBottom: '10px',
                        }}>
                            {data.description}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
}

export default Post;