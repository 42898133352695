import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDataType } from "../../utils/types";


const initialState: UserDataType = {
    uid: 0,
    email: '',
    firstname: '',
    is_active: false,
    is_admin: false,
    language: '',
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state: any, action: PayloadAction<UserDataType>) => {
            return action.payload;
        },
        clearUser: () => initialState,
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        }
    },
});

export const { setUser, clearUser, setLanguage } = userSlice.actions;
export default userSlice.reducer;