import { Button, Typography } from '@mui/material';
import { Box, maxWidth } from '@mui/system';
import { t } from 'i18next';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { API_CDN_URL } from '../../../utils/apiConf';


const ErrorPage: React.FC = () => {

    return (
        <>
        {/* <Helmet>
            <title>{t('titles.404')}</title>
            <meta name='description' content={t('descriptions.404')} />
            <meta property='og:title' content={t('titles.404')} />
            <meta property='og:description' content={t('descriptions.404')} />
            <meta property='twitter:title' content={t('titles.404')} />
            <meta property='twitter:description' content={t('descriptions.404')} />
            <meta property="og:url" content={window.location.href} />
        </Helmet> */}
        <Box sx={{
            fontSize: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            '@media(max-width: 500px)': {
                fontSize: '16px',
                '>img': {
                    maxWidth: '150px',
                }
            }
        }}>
            <img src={`${API_CDN_URL}/error.svg`} alt='error' width='250' style={{
                position: 'relative',
                marginBottom: '20px',
            }}/>

            <Typography variant='h1' sx={{
                padding: '10px 0',
                textAlign: 'center',
                fontSize: '2em',
                fontWeight: '500',
                letterSpacing: '2px',
                textShadow: '0 0 5px rgba(0,0,0,0.05)',
                position: 'relative',
                marginBottom: '10px',
            }}>{t('404.title')}</Typography>
            <Typography variant='h3' sx={{
                textAlign: 'center',
                fontSize: '1em',
                fontWeight: '500',
                letterSpacing: '2px',
                position: 'relative',
                marginBottom: '30px',
            }}>{t('404.description')}</Typography>
            <Link to='/' style={{color: '#fff'}}>
                <Button variant='contained' color='primary' sx={{
                    padding: '5px 50px',
                    fontSize: '.85em',
                    letterSpacing: '1px',
                    textTransform: 'initial',
                    position: 'relative',
                    marginBottom: '20px',
                    '@media(max-width: 500px)': {
                        padding: '5px 15px',
                        width: '100%',
                    }
                }}>
                    {t('404.return')}
                </Button>
            </Link>
        </Box>
        </>
    )
}

export default ErrorPage;