import React, { useEffect } from 'react';
import { ButtonBase, CircularProgress, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import { IngredientType, ProductType } from '../../utils/types';
import useApi from '../../hooks/useApi';
import { API_CDN_URL, API_INGREDIENT_GET_ENDPOINT, API_PRODUCT_GETBYSLUG } from '../../utils/apiConf';
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { t } from 'i18next';
import ProductCard from './components/ProductCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Helmet } from 'react-helmet';
import ErrorPage from '../error/components/ErrorPage';

const testProduct = {
    "id": 1,
    "name": "Dawtona Mus jabłkowy 720 g",
    "description": "Mus jabłkowy to niesamowity produtkt od najlepszego producenta Dawtona.",
    "ingredient_name": "Jabłko",
    "ean": "5901713002853",
    "kcal": 100,
    "protein": 0.5,
    "fat": 0.3,
    "saturated_fat": 0.1,
    "carbohydrates": 25,
    "carbohydrates_sugar": 20,
    "fiber": 2,
    "salt": 0.01,
    "size": 720,
    "size_type": "g",
    "slug": "dawtona-apple",
    "image": "dawtona_mus_jab.webp"
  }
  

const ProductPage: React.FC = () => {

    const slug = useParams<{slug: string}>();

    const [product, setProduct] = React.useState<ProductType>();
    const [ingredientInfo, setIngredientInfo] = React.useState<IngredientType>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [fetchError, setFetchError] = React.useState<boolean>(false);
    
    const [animationActive, setAnimationActive] = React.useState<boolean>(false);

    const { request } = useApi();

    const user = useSelector((state: RootState) => state.user);

    useEffect(() => {
        getProduct();
    }, []);

    const handleClick = () => {
        if (!animationActive) {
            setAnimationActive(true);
            setTimeout(() => {
                setAnimationActive(false);
            }, 2000);
        }   
    };

    const getProduct = async () => {
        setLoading(true);

        const language = user.language ? user.language : localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';

        const response = await request('GET', `${API_PRODUCT_GETBYSLUG}?slug=${slug.slug}`, {
            language,
            noMessage: true,
        });
        
        if (response.status === 200) {
            console.log(response.data.data.product)
            setProduct(response.data.data.product);
            setLoading(false);
            getIngredientDetails(response.data.data.product.ingredient_id);
        } else {
            setProduct(testProduct);
            setLoading(false);
            setFetchError(true);
        }
    }

    const getIngredientDetails = async (id: number) => {
        const language = user.language ? user.language : localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';
        
        const response = await request('GET', `${API_INGREDIENT_GET_ENDPOINT}?id=${id}`, {
            language,
            noMessage: true,
        });

        if (response.status === 200) {
            setIngredientInfo(response.data.data.ingredient);
        }
    }

    return (
        <>
        <Helmet>
            <title>{product ? product.name : 'Product'} {t('titles.product')}</title>
            <meta property='og:url' content={window.location.href} />
        </Helmet>

        <Container sx={{
            backgroundColor: '#f4f4f4',
            width: '100%',
            maxWidth: '100% !important',
        }}>
            {
                fetchError ? (
                    <ErrorPage />
                ) : (
                    <>
                        <Box sx={{
                            maxWidth: '1300px',
                            margin: '0 auto',
                            marginBottom: '20px',
                        }}>
                            <Container>
                                {
                                    loading ? (
                                        <Box sx={{
                                            display: 'grid',
                                            placeItems: 'center',
                                            minHeight: '200px',
                                        }}>
                                            <Typography variant="h5" sx={{
                                                textAlign: 'center',
                                                fontFamily: 'Noto Sans, sans-serif',
                                                fontWeight: 700,
                                                fontSize: '1.5rem',
                                                color: '#000',
                                            }}>
                                                <CircularProgress color='primary' />
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={{
                                            display: 'grid',
                                            placeItems: 'center',
                                            width: '100%',
                                        }}>
                                            <Box textAlign={'center'} sx={{
                                                margin: '20px 0',
                                                padding: '5px',
                                                borderRadius: '50%',
                                                border: '3px solid #fff',
                                            }}>
                                                <Box sx={{
                                                    backgroundColor: '#f2f2f2',
                                                    borderRadius: '50%',
                                                }}>
                                                    <Box 
                                                        className={animationActive ? 'rotate' : ''} 
                                                        onClick={handleClick} 
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '10px'
                                                        }}
                                                    >
                                                        <img src={`${API_CDN_URL}/uploads/${product?.image}`} alt={product?.name} width={85} style={{
                                                            height: 'auto',
                                                            borderRadius: '50%',
                                                        }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography variant="h3" sx={{
                                                fontFamily: 'Noto Sans, sans-serif',
                                                fontWeight: 300,
                                                fontSize: '2rem',
                                                color: '#000',
                                                marginBottom: '5px',
                                            }}>
                                                {product?.name}
                                            </Typography>
                                            <Box sx={{
                                                margin: '10px 0',
                                            }}>
                                                <ButtonBase sx={{
                                                    borderRadius: '25px',
                                                }}>
                                                    <Link to={`/ingredients/${ingredientInfo?.slug}`}>
                                                        <Box sx={{
                                                            fontFamily: 'Noto Sans, sans-serif',
                                                            fontWeight: 600,
                                                            fontSize: '1.15rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                            border: '1px solid #ff2c4f',
                                                            padding: '5px 12.5px',
                                                            color: '#ff2c4f',
                                                            background: '#fff',
                                                            borderRadius: '25px',
                                                            textTransform: 'lowercase',
                                                        }}>
                                                            <img src={`${API_CDN_URL}/uploads/${ingredientInfo?.image}`} width="22" />
                                                            {product?.ingredient_name}
                                                        </Box>
                                                    </Link>
                                                </ButtonBase>
                                            </Box>
                                            <Typography variant="h5" sx={{
                                                fontFamily: 'Noto Sans, sans-serif',
                                                fontWeight: 400,
                                                fontSize: '1em',
                                                display: 'flex',
                                                gap: '5px',
                                                alignItems: 'center',
                                                color: '#ff2c4f',
                                                cursor: 'pointer',
                                                ':hover > svg': {
                                                    transform: 'translateX(-5px)',
                                                },
                                                ':hover > a': {
                                                    textDecoration: 'underline !important',
                                                },
                                                
                                            }}>
                                                <BiSolidLeftArrowAlt style={{
                                                    transition: 'transform .1s ease',
                                                }} />
                                                <Link to='/products' style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    color: '#ff2c4f',
                                                }}>
                                                    {t('product.return')}
                                                </Link>
                                            </Typography>
                                            <Box sx={{
                                                display: 'grid',
                                                gap: '30px',
                                                padding: '30px',
                                                backgroundColor: '#fff',
                                                borderRadius: '10px',
                                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                                width: '100%',
                                                margin: '30px 0',
                                            }}>
                                                <Typography variant="h5" sx={{
                                                    fontFamily: 'Noto Sans, sans-serif',
                                                    fontWeight: 400,
                                                    fontSize: '1.25rem',
                                                    color: '#000',
                                                    textAlign: 'center',
                                                    maxWidth: {
                                                        xs: '90%',
                                                        sm: '90%',
                                                        md: '600px',
                                                    },
                                                    margin: '0 auto',
                                                }}>
                                                    {product?.description}
                                                </Typography>
                                                {product && <ProductCard product={product} /> }
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Container>
                        </Box>
                    </>
                )
            }
        </Container>
        </>
    )
}

export default ProductPage;