import { configureStore } from '@reduxjs/toolkit';
import recipesReducer from './slices/recipeSlice';
import commentReducer from './slices/commentSlice';
import recipeIdReducer from './slices/recipeIdSlice';
import preferencesReducer from './slices/preferencesSlice';
import searchIngredientsReducer from './slices/searchIngredientsSlice';
import userReducer from './slices/userDataSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        recipes: recipesReducer,
        recipeId: recipeIdReducer,
        comments: commentReducer,
        preferences: preferencesReducer,
        ingredients: searchIngredientsReducer
    },  
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;