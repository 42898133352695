import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import * as React from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 0',
                backgroundColor: '#121212',
                fontSize: '14px',
                color: '#fff',
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    margin: '20px 0',
                }}>
                    <a href="https://www.facebook.com/fitimpl" target="_blank" rel="noreferrer">
                        <IconButton sx={{ background: '#efefef' }}>
                            <FaFacebookF color='#212121' />
                        </IconButton>
                    </a>
                    <a href="https://x.com" target="_blank" rel="noreferrer">
                        <IconButton sx={{ background: '#efefef' }}>
                            <FaXTwitter color='#212121' />
                        </IconButton>
                    </a>
                </Box>
                <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '15px',
                    maxWidth: '300px',
                    fontSize: '16px',
                    marginBottom: '20px',
                    'a': {
                        color: '#fff',
                        textDecoration: 'none',
                        '&:hover': {
                            color: '#efefef',
                            textDecoration: 'underline',
                        }
                    }
                }}>
                    <Link to='/'>{t('footer.home')}</Link>
                    <Link to='/blog'>{t('footer.blog')}</Link>
                    <Link to='/rules'>{t('footer.rules')}</Link>
                    <Link to='/privacypolicy'>{t('footer.privacy')}</Link>
                    <Link to='/rodo'>{t('footer.rodo')}</Link>
                </Box>
                <p>© 2024 - FitIm</p>
            </Box>
        </footer>
    );
}

export default Footer;