import { Box, Container } from '@mui/system';
import * as React from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/useApi';
import { API_CDN_URL, API_PRODUCT_GETALL_ENDPOINT } from '../../utils/apiConf';
import { t } from 'i18next';
import { ProductType } from '../../utils/types';
import { Link } from 'react-router-dom';
import { ButtonBase, Card } from '@mui/material';
import { Helmet } from 'react-helmet';

const Products: React.FC = () => {
    const [products, setProducts] = React.useState<ProductType[]>([]);
    const [filteredProducts, setFilteredProducts] = React.useState<ProductType[]>([]);

    const user = useSelector((state: RootState) => state.user);

    const { request } = useApi();


    React.useEffect(() => {
        const language = user.language ? user.language : localStorage.getItem('fitim-lang') ? localStorage.getItem('fitim-lang') : 'pl';

        (async () => {
            const response = await request('GET', API_PRODUCT_GETALL_ENDPOINT, {
                language,
                noMessage: true,
            });
    
            if (response.status === 200 || response.status === 201)  {
                console.log('Products', response.data.data.products);
                const productsArray = response.data.data.products;
                setProducts(productsArray);
                setFilteredProducts(productsArray);
            } else {
                console.error('Products fetch error: ', response);
            }
        })()
    }, [user.language]);
    
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value.toLowerCase();
        const filteredProducts = products.filter(product => product.name.toLowerCase().includes(search));
        setFilteredProducts(filteredProducts);
    }

    return (
        <>
        <Helmet>
            <title>{t('titles.products')}</title>
            <meta name="description" content={t('descriptions.products')} />
            <meta property="og:title" content={t('titles.products')} />
            <meta property="og:description" content={t('descriptions.products')} />
            <meta property='twitter:title' content={t('titles.products')} />
            <meta property='twitter:description' content={t('descriptions.products')} />
            <meta property='og:url' content={window.location.href} />
        </Helmet>
        <Container>
            <Box sx={{
                backgroundColor: '#fdfdfd',
                padding: '20px 40px',
                borderRadius: '5px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                margin: '40px 0',
                fontSize: '20px',
            }}>
                <p>{t('products.title')}</p>
                <Box sx={{
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    marginBottom: '20px',

                    'input': {
                        padding: '10px',
                        borderRadius: '5px',
                        border: 'none',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        fontSize: {
                            xs: '0.75em',
                            sm: '1em',
                            md: '1.5em',
                        },
                    }
                }}>
                    <input onChange={handleSearch} type="text" placeholder={t('products.search.placeholder')} />
                </Box>

                    { products && products.length > 0
                     
                    ? 
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                        gap: '20px',
                        justifyContent: 'center',
                    }}>
                        {filteredProducts.map((product, index) => {
                        return (
                            <Link key={index} to={`/products/${product.slug.toLowerCase()}`}>
                                <Card sx={{
                                    gap: '10px',
                                    borderRadius: '5px',
                                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                    fontSize: '0.75em',
                                    background: '#fff',
                                    transition: 'background 0.05s ease-in-out',
                                    ':hover': {
                                        background: '#f2f2f2',
                                    }
                                }}>
                                    <ButtonBase sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '10px',
                                    }}>
                                        <img src={`${API_CDN_URL}/uploads/${product.image}`} alt={product.name} style={{
                                            width: '60px',
                                            height: '60px',
                                            objectFit: 'cover',
                                            borderRadius: '50%',
                                        }} />
                                        <p style={{ textAlign: 'center' }}>{product.name}</p>
                                    </ButtonBase>
                                </Card>
                            </Link>
                            )
                        })}
                    </Box>
                    :   
                    <Box textAlign={'center'}>
                        <p>-</p>
                    </Box>}
            </Box>
        </Container>
        </>
    );
}

export default Products;