import { Box, Button, Divider, Drawer, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

import { IoMenu } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import i18n from '../../i18n';
import { API_CDN_URL } from '../../utils/apiConf';

interface NavbarProps {
    changeLanguage: (lang: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ changeLanguage }) => {

    const user = useSelector((state: RootState) => state.user);

    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const [currentLang, setCurrentLang] = useState<string>('');

    const authContext = useContext(AuthContext);

    if (!authContext) {
        throw new Error('Komponent musi być używany wewnątrz AuthContext.Provider');
    }
    
    const { isAuthenticated, setIsAuthenticated } = authContext;

    const { t } = useTranslation();

    useEffect(() => {
        if (user.language) {
            setCurrentLang(user.language);
            i18n.changeLanguage(user.language);
        } else {
            if (localStorage.getItem('fitim-lang')) {
                const lang = localStorage.getItem('fitim-lang') as string;
                setCurrentLang(lang);
                i18n.changeLanguage(lang);
                changeLanguage(lang);
            } else {
                setCurrentLang('pl');
                i18n.changeLanguage('pl');
            }
        }
    }, [user.language]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const drawer = (
        <Box
            onClick={handleDrawerToggle} 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& a': {
                    fontSize: '16px',
                    lineHeight: '1.5',
                    padding: '5px 0',
                }
            }}
        >
            <Box sx={{textAlign: 'center', margin: '10px 0'}}>
                <Link to='/'>
                    <img src={`${API_CDN_URL}/logo.svg`} alt="logo" width="130" />
                </Link>
            </Box>
            <Divider />
            <Button>
                <Link to='/recipes'>{t('navbar.recipes')}</Link>
            </Button>
            <Button>
                <Link to='/blog'>{t('navbar.blog')}</Link>
            </Button>
            <Button>
                <Link to='/ingredients'>{t('navbar.ingredients')}</Link>
            </Button>
            <Button>
                <Link to='/products'>{t('navbar.products')}</Link>
            </Button>
            <Button>
                <Link to='/rules'>{t('navbar.rules')}</Link>
            </Button>
            {
                isAuthenticated && user
                ? (
                    <>
                        <Button>
                            <Link to='/panel/dashboard'>{t('navbar.dashboard')}</Link>
                        </Button>
                        <Button>
                            <Link to='/signout'>{t('navbar.logout')}</Link>
                        </Button>
                    </>
                )
                : (
                    <Button>
                        <Link to='/signin'>{t('navbar.login')}</Link>
                    </Button>
                )
            }
        </Box>
    );

    const handleLangChange = () => {
        const newLang = currentLang === 'pl' ? 'en' : 'pl';
        setCurrentLang(newLang);
        
        changeLanguage(newLang);
    }

    return (
        <Box sx={{
            background: '#212121',
            borderBottom: '2px solid #121212',
            display: 'flex',
            alignItems: 'center',
            zIndex: 100,
        }}>
            <Link to='/'>
                <img style={{filter: 'invert(100%)', padding: '10px 0px 10px 40px'}} className='navbar-logo' src={`${API_CDN_URL}/logo.svg`} alt="logo" width="140" />
            </Link>
            {/* PC */}
            <Box sx={{
                marginLeft: 'auto', 
                color: '#fff', 
                padding: '0 30px 0 10px', 
                gap: '20px',
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                },
                alignItems: 'center',
            }}>
                {/* select with language selection i18 */}
                <Box sx={{ 
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex',
                    },
                    gap: '20px',
                    fontSize: '15px',
                    marginLeft: '30px',
                }}>
                    <Link style={{color: '#fff'}} to='/recipes'>{t('navbar.recipes')}</Link>
                    <Link style={{color: '#fff'}} to='/blog'>{t('navbar.blog')}</Link>
                    <Link style={{color: '#fff'}} to='/ingredients'>{t('navbar.ingredients')}</Link>
                    <Link style={{color: '#fff'}} to='/products'>{t('navbar.products')}</Link>
                </Box>
                <FormControl>
                    <Select
                        value={currentLang}
                        onChange={handleLangChange}
                        renderValue={(value) => (
                            <img src={`${API_CDN_URL}/flags/${value}.png`} alt={`language ${value} flag`} width="20" />
                        )}
                        
                        sx={{
                            border: 'none',
                            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            'svg': {
                                display: 'none',
                            },
                            '.MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px !important',
                            }
                        }}
                    >
                        <MenuItem value='pl' sx={{
                            display: 'flex',
                            gap: '15px',
                            justifyContent: 'space-between',
                            'img': {
                                filter: 'drop-shadow(0 0 1px rgba(0,0,0, .35))',
                            }
                        }}>
                            <span>Język polski</span>
                            <img src={`${API_CDN_URL}/flags/pl.png`} alt="polish language flag" width="20" />
                        </MenuItem>
                        <MenuItem value='en' sx={{
                            display: 'flex',
                            gap: '15px',
                            justifyContent: 'space-between',
                            'img': {
                                filter: 'drop-shadow(0 0 1px rgba(0,0,0, .35))',
                            }
                        }}>
                            <span>English</span>
                            <img src={`${API_CDN_URL}/flags/en.png`} alt="usa language flag" width="20" />
                        </MenuItem>
                    </Select>
                </FormControl>
                {
                    isAuthenticated && user
                    ? (
                        <>
                            <Link style={{color: '#fff'}} to='/panel/dashboard'>{t('navbar.dashboard')}</Link>
                            <Link style={{color: '#fff'}} to='/signout'>{t('navbar.logout')}</Link>
                        </>
                    )
                    : <Link style={{color: '#fff'}} to='/signin'>{t('navbar.login')}</Link>
                }
            </Box>
            {/* Mobile */}
            <Box sx={{
                marginLeft: 'auto',
                display: {
                    xs: 'flex',
                    sm: 'flex',
                    md: 'none',
                }
            }}>
                <IconButton 
                    onClick={handleDrawerToggle}
                    sx={{
                        background: 'rgba(255,255,255,.05)',
                        marginRight: '40px',
                    }}
                >
                    <IoMenu color='#fff' />
                </IconButton>
            </Box>
            <Drawer
                variant='temporary'
                anchor='right'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        background: '#fff',
                        color: '#fff',
                        width: '240px',
                    }
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default Navbar;